//@ts-nocheck
import './scss/index.scss'
import 'swiper/css';
import 'swiper/css/navigation';

import HystModal from 'hystmodal';
import Swiper from "swiper"
import {Navigation} from "swiper/modules";

const slider = new Swiper(`.js-melke-slider`, {
    // Optional parameters
    modules: [Navigation],
      speed: 700,
      direction: 'horizontal',
      centeredSlides: true,
      loop: true,
    // Navigation arrows
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },

      breakpoints: {
        320: {
            slidesPerView: 1,
        },

        991: {
            slidesPerView: 2,
        }
      }
});


    document.querySelector(`.js-melke-about-company`).style.overflow = 'hidden';
    const blocksInfo = document.querySelectorAll('.melke-block-info__card');
    const blocksUniqueness = document.querySelectorAll('.melke-uniqueness__card');
    /* const blockMission = document.querySelector('.melke-mission__wrap'); */
    const boxAnimate = document.querySelectorAll('.melke-coex__bg-group');
    const parallaxBlocks = document.querySelectorAll('.parallax-block');
    
    /* Observer для обычных блоков START */
    const observer = new IntersectionObserver((entries) => {
        /* console.log(entries); */

        entries.forEach((item, i) => {
            // console.log(item.target);
            const img = item.target.querySelector('.melke-block-info__right');
            const info = item.target.querySelector('.melke-block-info__left');

            const bg = item.target.querySelectorAll('.parallax-block');
            if(item.isIntersecting) {
                if(img) {
                    img.classList.add('js-animate');
                }
                if(info) {
                    info.classList.add('js-animate');
                }
                if(bg) {
                    let posY;
                    let sec = document.querySelectorAll('section');
                    
                    function paralax() {
                        posY = window.pageYOffset;
                        console.log();
                        sec.forEach(item => {
                            let block= item?.querySelectorAll('.parallax-block');
                            console.log(block);
                            block.forEach(el => {
                                el.style.top = posY * 0.3 + 'px';
                            })
                        })
                    }
                    window.addEventListener('scroll', paralax);
                }
            }
        })
    }, {
        threshold: 0.2,
    });

    parallaxBlocks.forEach(block => {
        observer.observe(block)
    });
    blocksInfo.forEach(block => observer.observe(block));
    blocksUniqueness.forEach(block => observer.observe(block));
    boxAnimate.forEach(item => {
        observer.observe(item);
    })
    /* Observer для обычных блоков END */

    const videoModal = new HystModal({
        linkAttributeName: 'data-hystmodal',
        catchFocus: true,
        waitTransitions: true,
        closeOnEsc: true,
        beforeOpen: function(modal){
            // console.log('Message before opening the modal');
            // console.log(modal); //modal window object
        },
        afterClose: function(modal){
            // console.log('Message after modal has closed');
            // console.log(modal); //modal window object
        },
    });

/*     (function() {
        let posY;
        let sec = document.querySelectorAll('section');
        
        function paralax() {
            posY = window.pageYOffset;
            sec.forEach(item => {
                let block= item?.querySelector('.parallax-block');
                if (block) {
                    block.style.top = posY * 0.3 + 'px';
                }
            })
        }
        window.addEventListener('scroll', paralax);
    })(); */